import React from 'react'
import { Application } from '../../core/app';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { BOOLEAN_STRING } from '../../constants/api';
import ChatPMS from './chatPMS';
import ChatOld from './chatOld';

import '../../styles/modules/chat.css';

function Chat({ enablePms, hideChat, whiteLabel }) {
  var chat = ''
  if (hideChat === false) {
      if (enablePms === BOOLEAN_STRING.TRUE) {
          chat = <ChatPMS />
      } else {
          chat = <ChatOld />
      }
  }
  return (
    <div>
      <h2 className="section-title">{whiteLabel.labels['Lender Chat']}</h2>
      {chat}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    enablePms: state.client.data.enablePms,
    hideChat: state.client.data.hideChat,
    whiteLabel : state.whiteLabel
  }
}



export default connect(mapStateToProps, {})(Chat);