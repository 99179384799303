import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Tooltip from 'rc-tooltip';
import { toUSD } from '../../../helpers/formatters';
import 'rc-tooltip/assets/bootstrap.css';

import {
  makePayment as requestMakePayment,
  getPaymentMessage,
} from '../../../api/payment';
// Import actions
import { setPaymentMethod, showLoading, hideLoading } from '../../../actions/app';
import { fetchLoanAccounts } from '../../../actions/loan-account';

// Import components
import CardTermsConditions from '../../../components/payment/card-terms-conditions';
import AchTermsConditions from '../../../components/payment/ach-terms-conditions';
import PadTermsConditions from '../../../components/payment/pad-terms-conditions';
import InfoModal from '../../../components/application/modals/info-modal';
import Modal from '../../../components/application/modal';
import Notice from '../../../components/application/notice';
import PayixButton from '../../../containers/payix-button';
import { Application } from '../../../core/app';

class PaymentForm extends Component {
  constructor(props) {
    super(props);
    if (this.props.client.autoFillPmtAmt) {
      let proccessedAmount = this.props.client.autoFillPmtAmt ? parseFloat(this.props.loanAccount.regularPmtAmt).toFixed(2) : '';
      this.props.setAmount(proccessedAmount.toString());
      this.state = {
        isModalOpen: false,
        modal: null,
        modalWidth: '500px',
        mustAcceptPaymentMessage: false,
        paymentMessage: null,
        notice: '',
        amount: proccessedAmount
      };
    } else {
      this.state = {
        isModalOpen: false,
        modal: null,
        modalWidth: '500px',
        mustAcceptPaymentMessage: false,
        paymentMessage: null,
        notice: '',
        amount: ''
      };
    }

    getPaymentMessage()
    .then(response => {
      let dataResponse = response.data;
      if (dataResponse.result.paymentMessage != null) {
        this.state.paymentMessage = (this.props.whiteLabel.labels['Payment Message Text'] != null && this.props.whiteLabel.labels['Payment Message Text'] != '') ? this.props.whiteLabel.labels['Payment Message Text'] : dataResponse.result.paymentMessage;
        this.state.mustAcceptPaymentMessage = true;
      }
    });
    this.handlePayableAmount = this.handlePayableAmount.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.client.autoFillPmtAmt != prevProps.client.autoFillPmtAmt){
      let proccessedAmount = this.props.client.autoFillPmtAmt ? parseFloat(this.props.loanAccount.regularPmtAmt) : '';
      this.props.setAmount (proccessedAmount.toString());
      this.setState({
        amount: this.props.client.autoFillPmtAmt ? parseFloat(this.props.loanAccount.regularPmtAmt).toFixed(2) : ''
      });
    }
  }
  

  /**
   * Close the modal.
   */
  closeModal = () => {
    if (this.state.isModalOpen) {
      this.setState({
        isModalOpen: false,
        modal: null,
        modalWidth: '500px'
      }); 
    }
  }

  acceptPaymentMessage = () => {
    this.setState({
      mustAcceptPaymentMessage: false,
    });
  };

  /**
   * Get the total amount to pay.
   */
  getAmount() {
    let transactionFee = !this.props.transactionFee ? 0 : this.props.transactionFee;
    let amount = (this.props.amount === '') ? 0 : this.props.amount;
    if ((amount == 0) && (this.props.client.autoFillPmtAmt)) {
      amount = this.props.loanAccount.regularPmtAmt;
    } 
    let result = parseFloat(amount) + transactionFee;
    return result.toFixed(2);
  }

  /**
   * Check if the form has all required fields full.
   */
  isFormFull() {
    let { amount, selectedPaymentMethod } = this.props;
    if(amount > 0){
      return (amount && selectedPaymentMethod);
    }
  }

  handleMakePayment = (event) => {
    event.preventDefault();
    
    let { loanAccount } = this.props;

    if (this.isFormFull()) {
      if (loanAccount.hasScheduled) {
          this.setState({
                isModalOpen: true,
                modal: 'HAS_SCHEDULED_PAYMENT',
                modalWidth: '750px'
          });
      } else {
          this.showPaymentMethodConditions();
      }
    }

    let { selectedPaymentMethod } = this.props;

    if (this.isFormFull()) {
      if (selectedPaymentMethod.paymentType === 1) {
        this.setState({
          isModalOpen: true,
          modal: 'CARD_TERMS_CONDITIONS',
          modalWidth: '750px'
        });
      } else if (selectedPaymentMethod.paymentType === 2) {
        this.setState({
          isModalOpen: true,
          modal: 'ACH_TERMS_CONDITIONS',
          modalWidth: '750px'
        });
      } else if (selectedPaymentMethod.paymentType === 3) {
        this.setState({
          isModalOpen: true,
          modal: 'PAD_TERMS_CONDITIONS',
          modalWidth: '750px'
        });
      }
    }
  }

  handleBeforeNoticeMakePayment = (event) => {
      event.preventDefault();
      
      this.showPaymentMethodConditions();
  }

  handleNotifyPmtLessThanDue = () => {
      let { amount } = this.props;
      //Show the webpop if payment is less than total amount due
      if (this.props.notifyPmtLessThanDue != '0' && parseFloat(amount).toFixed(2) < parseFloat(this.props.loanAccount.calNextPayment)) {
          this.setState({
              isModalOpen: true,
              modal: 'NOTICE_PMT_LESS_AMT_DUE',
              notice: this.props.whiteLabel.labels['Amount is less than total amount due'],
              modalWidth: '500px'
          })
      } else {
          this.makePayment()
      }
  }

  /**
   * Make a new payment.
   */
  makePayment = () => {
    let { amount, loanAccount, selectedPaymentMethod, onSuccess } = this.props;
    
    this.closeModal();
    this.props.showLoading();

    requestMakePayment({
      id: selectedPaymentMethod.id,
      paymentType: selectedPaymentMethod.paymentType,
      loanId: loanAccount.id,
      amount: parseFloat(amount).toFixed(2)
    }).then((response) => {
      let data = response.data;

      this.props.hideLoading();

      // Payment was declined or whatever...
      if (data.status === 0) {
        this.setState({
          isModalOpen: true,
          modal: 'NOTICE',
          notice: data.message
        });
      }

      // Payment was successful...
      if (data.status === 1 && data.statusCode === 200) {
        onSuccess(data.result);

        this.props.showLoading();

        return Promise.all([
          this.props.fetchLoanAccounts()
        ]).then(() => {
          this.props.hideLoading();
        }).catch(() => {
          this.props.hideLoading();
        });
        
        this.setState({
          amount: this.props.client.autoFillPmtAmt ? parseFloat(this.props.loanAccount.regularPmtAmt).toFixed(2) : ''
        });
      }
    })
    .catch(() => {
      Application.showInternalError();
      this.props.hideLoading();
    })
  }

  /**
   * Handler for on change event on the amount field.
   * 
   * @param event
   */
  handlePayableAmount = (event) => {
    this.setState({
      amount: event.target.value
    });
    this.props.onChangeAmount(event);
  }
  

  showPaymentMethodConditions() {
      let { selectedPaymentMethod } = this.props;

      if (selectedPaymentMethod.paymentType === 1) {
          this.setState({
              isModalOpen: true,
              modal: 'CARD_TERMS_CONDITIONS',
              modalWidth: '750px'
          });
      } else if (selectedPaymentMethod.paymentType === 2) {
          this.setState({
              isModalOpen: true,
              modal: 'ACH_TERMS_CONDITIONS',
              modalWidth: '750px'
          });
      } else if (selectedPaymentMethod.paymentType === 3) {
          this.setState({
              isModalOpen: true,
              modal: 'PAD_TERMS_CONDITIONS',
              modalWidth: '750px'
          });
      }
  }

  /**
   * Render the exact content for the modal.
   */
  renderModalContent() {
    if (this.state.modal !== 'NOTICE' && this.state.mustAcceptPaymentMessage) {
      return (
          <InfoModal
              content={this.state.paymentMessage}
              leftButtonHandler={this.closeModal}
              rightButtonHandler={this.acceptPaymentMessage}
              whiteLabel={this.props.whiteLabel}
          />
      );
    }
    switch (this.state.modal) {
      case 'ACH_TERMS_CONDITIONS' :
        return this.renderAchTermsAndConditionsModal();
      case 'PAD_TERMS_CONDITIONS' :
        return this.renderPadTermsAndConditionsModal();
      case 'CARD_TERMS_CONDITIONS' :
          return this.renderCardTermsAndConditionsModal();
      case 'PAYMENT_METHOD' :
        return (
            <InfoModal
                content={this.state.paymentMessage}
                leftButtonHandler={this.closeModal}
                rightButtonHandler={this.makePayment}
                whiteLabel={this.props.whiteLabel}
            />
        );
      case 'NOTICE' :
        return <Notice 
          message={this.state.notice}
          onClose={this.closeModal}
          whiteLabel={this.props.whiteLabel} />
      case 'HAS_SCHEDULED_PAYMENT' :
        return (
          <InfoModal
              content={(this.props.whiteLabel.labels['Message Scheduled Payment'] != null && this.props.whiteLabel.labels['Message Scheduled Payment'] != '') ? this.props.whiteLabel.labels['Message Scheduled Payment'] : this.props.client.messageScheduledPayment}
              leftButtonHandler={this.closeModal}
              rightButtonHandler={this.handleBeforeNoticeMakePayment}
              whiteLabel={this.props.whiteLabel}
          />
        );
      case 'NOTICE_PMT_LESS_AMT_DUE' :
        return <InfoModal 
          content={this.state.notice}
          leftButtonHandler={this.closeModal}
          rightButtonHandler={this.makePayment}
          whiteLabel={this.props.whiteLabel} />
      default :
        return null;
    }
  }

  /**
   * Render the Card terms and contidions modal.
   *
   * @param {*} data
   */
  renderCardTermsAndConditionsModal() {
    let { client, selectedPaymentMethod } = this.props;
    let props = {
      amount: this.getAmount(),
      company: {
        name: client.legalCompanyName,
        website: client.website,
        phone: client.phoneNumber,
        workingHour: client.workingHour
      },
      disclaimer:{
        borrowerOnetimeCardDisclosure: client.borrowerOnetimeCardDisclosure,
        borrowerRecurringCardDisclosure: client.borrowerRecurringCardDisclosure,
        borrowerOnetimeAchDisclosure: client.borrowerOnetimeAchDisclosure,
        borrowerRecurringAchDisclosure: client.borrowerRecurringAchDisclosure
      },
      lastFour: selectedPaymentMethod ? selectedPaymentMethod.lastFour : null,
      onSubmit: this.handleNotifyPmtLessThanDue
    };

    return (
        <CardTermsConditions
            {...props}
            onCancel={this.closeModal} 
            whiteLabel={this.props.whiteLabel} />
    )
  }

  /**
   * Render the Ach terms and contidions modal.
   *
   * @param {*} data
   */
  renderAchTermsAndConditionsModal() {
    let { client, selectedPaymentMethod } = this.props;
    let props = {
      amount: this.getAmount(),
      company: {
        name: client.legalCompanyName,
        website: client.website,
        phone: client.phoneNumber,
        workingHour: client.workingHour
      },
      disclaimer:{
        borrowerOnetimeCardDisclosure: client.borrowerOnetimeCardDisclosure,
        borrowerRecurringCardDisclosure: client.borrowerRecurringCardDisclosure,
        borrowerOnetimeAchDisclosure: client.borrowerOnetimeAchDisclosure,
        borrowerRecurringAchDisclosure: client.borrowerRecurringAchDisclosure
      },
      lastFour: selectedPaymentMethod ? selectedPaymentMethod.lastFour : null,
      onSubmit: this.handleNotifyPmtLessThanDue
    };

    return (
        <AchTermsConditions
            {...props}
            onCancel={this.closeModal} 
            whiteLabel={this.props.whiteLabel}/>
    )
  }


  /**
   * Render the Ach terms and contidions modal.
   * 
   * @param {*} data 
   */
  renderPadTermsAndConditionsModal() {
    let { client, selectedPaymentMethod } = this.props;
    let props = {
      amount: this.getAmount(),
      company: {
        name: client.legalCompanyName,
        website: client.website,
        phone: client.phoneNumber,
        email: client.customerCareEmail,
        workingHour: client.workingHour
      },
      lastFour: selectedPaymentMethod ? selectedPaymentMethod.lastFour : null,
      onSubmit: this.handleNotifyPmtLessThanDue
    };

    return (
      <PadTermsConditions 
        {...props}
        onCancel={this.closeModal}
        whiteLabel={this.props.whiteLabel} />
    )
  }

  /**
   * Render the component view.
   */
  render() { 
    return (
      <div>
        <form className="margin-top-15" onSubmit={this.handleMakePayment}>
          { this.props.paymentMethods }

          <div className="form-group">
            <label className="load-account__money-amount">{this.props.whiteLabel.labels['Enter amount you would like to pay']}</label>
            <div className="money-input">
              <span className="money-input__addon">
                <i className="fas fa-dollar-sign"></i>
              </span>

              {/**
                ** Render the input for the amount.
                **/}
              <input 
                autoFocus={true}
                className="money-input__textfield" 
                type="text" 
                maxLength="13"
                value={this.state.amount}
                onChange={this.handlePayableAmount} />
            </div>
          </div>

          {/**
            ** Show the convenience fee if apply. 
            **/}
          {(this.props.transactionFee)
            ? (
              <div className="load-account__total">
                <span>
                  {this.props.whiteLabel.labels['Convenience Fee']}&nbsp;
                  <Tooltip placement="top" overlay={this.props.transactionMargin}>
                    <i className="fas fa-info-circle color-green op-4"></i>
                  </Tooltip>
                </span>
                <span className="color-red">{toUSD(this.props.transactionFee)}</span>
              </div>
            )
            : null
          }

          <div className="load-account__total">
            <span className="gothammedium-font">{this.props.whiteLabel.labels['Total Payable']}</span>
            <span className="gothammedium-font">{toUSD(this.getAmount())}</span>
          </div>

          <PayixButton 
            className="button--full"
            type="submit"
            disabled={!this.isFormFull()}>
            {this.props.whiteLabel.labels['Pay']} {toUSD(this.getAmount())}
          </PayixButton>
        </form>

        <Modal 
          isOpen={this.state.isModalOpen}
          width={this.state.modalWidth}
          onClose={this.closeModal}>

          {this.renderModalContent()}
        </Modal>
       
        <div className="load-account__disclaimer">
            <span className="gothambook-font">{(this.props.whiteLabel.labels['Message Payment Button'] != null && this.props.whiteLabel.labels['Message Payment Button'] != '') ? this.props.whiteLabel.labels['Message Payment Button'] : this.props.client.messagePaymentButton}</span>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.client.data,
    transactionFee: state.app.payment.transactionFee,
    transactionMargin: state.client.transactionMargin,
    whiteLabel: state.whiteLabel,
    loan: state.app.loanAccounts
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    setPaymentMethod, showLoading, hideLoading, fetchLoanAccounts
  }, dispatch);
}
 
export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
