import React, { Component } from 'react';
import { Application } from '../core/app';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { reset } from 'redux-form';
import StatementList from '../components/statements/list';
import { statementListAction } from '../actions/loan-account';

import { getLoanAccountsInfo } from '../api/loan-account';

// Import actions
import { showLoading, hideLoading, showMessageBar } from '../actions/app';


// Import styles
import '../styles/modules/statements/index.css';

class Statements extends Component {

  state = {
    loanAccounts: [],
    selected: null,
    selectedLoanId: null
  }

  componentDidMount() {
    this.fetchInitialData();
  }

  /**
   * Fetch the loan accounts and the payment methods.
   */
  fetchInitialData = () => {
    Promise.all([
      this.getLoanAccounts(),
    ]).then(() => {
      this.props.statementListAction(this.state.selectedLoanId);
    })
      .catch(() => {
        Application.showInternalError();
      });
  };

  /**
   * Fetch the loan accounts.
   */
  getLoanAccounts() {
    return getLoanAccountsInfo()
      .then((response) => {
        let data = response.data;

        if (data.status === 1 && data.statusCode === 200) {
          let loans = data.result.loans;
          if (loans.length > 0) {
            this.setState({
              loanAccounts: loans,
              selected: 0,
              selectedLoanId: loans[0].loan.id
            });
          }
        } else {
          this.props.showMessageBar({
            status: 'error',
            text: data.message
          });
        }
      })
      .catch(() => {
        this.showInternalError();
      });
  }

  


  /**
   * Handle the event when the user change the loan account.
   * 
   * @param event
   */
  handleChangeLoanAccount = (event) => {
    if (this.props.statements.isLoading == false) {
      this.state.selectedLoanId = parseInt(event.target.value);
      this.props.statementListAction(parseInt(event.target.value));
    }

  }

  /**
   * Render the component view.
   */
  render() {
    let url = this.props.match.url;
    let { loanAccounts, selectedLoanId } = this.state;
    return (
      <div className="content">
        <div className="flex-block flex-block--space-between">
          <div className="contact-info__loan-statements">
            <label className="contact-info__loan-accounts-label">Select Account</label>
            <select
              className="custom-select-in"
              onChange={this.handleChangeLoanAccount}>

              {loanAccounts.map((loanAccount) =>
                <option key={loanAccount.id} value={loanAccount.loan.id}>
                  {loanAccount.loan.loanNo}
                </option>
              )}
            </select>
          </div>
        </div>
        <div className="app-statements">
          {this.props.statements.isLoading == true && (<div className="loader-in"> </div>)}
          {this.props.statements.isLoading == false && (<StatementList selectedLoanId={this.state.selectedLoanId} tableData={this.props.statements.statementList} />)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    whiteLabel: state.whiteLabel,
    statements: state.statements
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    showLoading, hideLoading, showMessageBar, reset, statementListAction
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Statements));