import store from '../config/store';
import { showMessageBar } from '../actions/app';
import { INTERNAL_ERROR } from '../constants/errors';

export const ROOT_URL = process.env.REACT_APP_ROOT_URL;

class App {
  /**
   * Get the current access token.
   */
  getAccessToken() {
    if (!this.accessToken) {
      this.accessToken = localStorage.getItem('accessToken');
    }

    return this.accessToken;
  }

  /**
   * Set an access token for the application.
   * 
   * @param {*} token
   */
  setAccessToken(token) {
    localStorage.setItem('accessToken', token);
  }

  /**
   * Remove the access token.
   */
  removeAccessToken() {
    localStorage.removeItem('accessToken');
    this.accessToken = null;
  }

  /**
   * Get the data for the current user authenticated.
   */
  getAuthUserData() {
    if (!this.userData) {
      this.userData = JSON.parse(localStorage.getItem('userData'));
    }

    return this.userData;
  }

  /**
   * Set the data for the current athenticated user.
   * 
   * @param data
   */
  setAuthUserData(data) {
    localStorage.setItem('userData', JSON.stringify(data));
    localStorage.setItem('language', data.languageCode);
  }

  /**
   * Store the accessToken and the user data and configure the app 
   * for the correct use of the authenticated user.
   */
  configureAuthUser(result) {
    // Set the accessToken and configure it on axios configuration.
    this.setAccessToken(result.accessToken);
    this.setAuthUserData(result.data);
    window.axios.defaults.headers.common['accessToken'] = Application.getAccessToken();
  }

  /**
   * Clears all data associated with the user currently logged in.
   */
  logout() {
    this.removeAccessToken();
    localStorage.removeItem('userData');
    this.userData = null;
    localStorage.removeItem('language');
    sessionStorage.removeItem('acceptedBkUdfPopup');
  }

  /**
   * Get the link for show the privacy policy.
   */
  getPrivacyPolicy() {
    return ROOT_URL + '/app/privacy-notice.pdf';
  }

  /**
   * Display a flash bar with a success message.
   */
  showSuccessMessage(message) {
    store.dispatch(showMessageBar({
      status: 'success',
      text: message
    }));
  }

  /**
   * Display a flash bar with a error message.
   */
  showErrorMessage(message) {
    store.dispatch(showMessageBar({
      status: 'error',
      text: message
    }));
  }

  /**
   * Show a generic message for a internal error without give details.
   */
  showInternalError() {
    this.showErrorMessage(INTERNAL_ERROR);
  }
}

export let Application = new App();