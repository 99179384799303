import * as types from '../constants/actions/client';

const defaultState = {
  clientDataLoaded: false,
  backgroundImageLoaded: false,
  clientImages: null,
  data: {
    hideChatInBp: '1',
    languages: []
  },
  backgroundImage: '',
  cards: [],
  transactionMargin: '',
  paymentFreqs: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${types.GET_CLIENT_DETAILS}_FULFILLED`:
      const { message, result: { data } } = action.payload.data;
      
      if (message === 'Client details has been found') {
        return {...state, data, clientDataLoaded: true};
      }

      return state;
    case `${types.GET_BACKGROUND_IMAGE}_FULFILLED`:
      const { result: { background_image } } = action.payload.data;
      if(background_image.length > 0) {
        return {...state, backgroundImage: background_image, backgroundImageLoaded: true};
      }
      return state;
    case types.SET_PAYMENT_CARDS :
      return {...state, cards: action.payload}
    case types.SET_TRANSACTION_MARGIN :
      return {...state, transactionMargin: action.payload}
    case `${types.SET_CLIENT_IMAGES}_FULFILLED` :
      const { status, statusCode, result } = action.payload.data;
      
      if (status === 1 && statusCode === 200) {
        return {...state, clientImages: result.data}
      }

      return state;
    case `${types.GET_PAYMENT_FREQS}_FULFILLED` :
      return {...state, paymentFreqs: action.payload.data.result.data}

    default:
      return state;
  }
};
