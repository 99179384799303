import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Application } from '../../core/app';

// Import components
import AddEmailModal from '../application/modals/add-email';
import AddPhoneModal from '../application/modals/add-phone';
import VerifyPhone from '../application/modals/verify-phone';
import Dropdown from '../../components/application/dropdown/index';
import DropdownItem from '../../components/application/dropdown/item';
import EditContactInformation from '../application/modals/edit-contact-information';
import Modal from '../application/modal';
import ResendEmailVerification from '../application/modals/resend-email-verification';
import PayixButton from '../../containers/payix-button';

import { INTERNAL_ERROR } from '../../constants/errors';
import '../../styles/modules/widgets/email-list.css';

// Import actions
import { showLoading, hideLoading, showMessageBar } from '../../actions/app';
import { getStates } from '../../actions/auth';

// API
import { getLoanAccountsInfo } from '../../api/loan-account';
import {
  requestDeleteEmail,
  requestMakeEmailAsPrimary,
  requestDeletePhone,
  requestMakePhoneAsPrimary,
  requestResendEmailConfirmation,
  getDetails
} from '../../api/client';
import {
  resendOtpCode
} from '../../api/auth';


class ContactInformation extends Component {
  state = {
    details: null,
    loanAccounts: [],
    selected: null,
    isModalOpen: false,
    modal: null
  }

  selectedEmail = null;

  componentDidMount() {
    this.fetchInitialState();
  }

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      modal: null
    });
  }

  showInternalError = () => {
    this.props.showMessageBar({
      status: 'error',
      text: INTERNAL_ERROR
    });
  }

  /**
   * Fetch the initial data.
   */
  fetchInitialState() {
    this.props.showLoading();

    Promise.all([this.getLoanAccounts(), this.getDetails(), this.props.getStates()])
      .then(() => {
        this.props.hideLoading();
      })
      .catch(() => {
        this.showInternalError();
      });
  }

  /**
   * Get the loan accounts with its details.
   */
  getDetails() {
    return getDetails()
      .then((response) => {
        let data = response.data;

        if (data.status === 1 && data.statusCode === 200) {

          Application.setAuthUserData(data.result);
          this.setState({
            details: data.result
          });
        } else {
          this.props.showMessageBar({
            status: 'error',
            text: data.message
          });
        }
      })
      .catch(() => {
        this.showInternalError();
      })
  }

  /**
   * Fetch the loan accounts.
   */
  getLoanAccounts() {
    return getLoanAccountsInfo()
      .then((response) => {
        let data = response.data;

        if (data.status === 1 && data.statusCode === 200) {
          let loans = data.result.loans;

          if (loans.length > 0) {
            this.setState({
              loanAccounts: loans,
              selected: 0
            });
          }
        } else {
          this.props.showMessageBar({
            status: 'error',
            text: data.message
          });
        }
      })
      .catch(() => {
        this.showInternalError();
      });
  }

  /**
   * Get the selected loan account contac information.
   */
  getSelectedAccount() {
    return this.state.loanAccounts[this.state.selected]
  }

  /**
   * Display the add email modal.
   */
  showAddEmailModal = () => {
    this.setState({
      isModalOpen: true,
      modal: 'ADD_EMAIL'
    });
  }

  /**
   * Display the add phone number modal.
   */
  showAddPhoneModal = () => {
    if (this.state.details != undefined && this.state.details.installMobilePhones.length < 3) {
        this.setState({
          isModalOpen: true,
          modal: 'ADD_PHONE'
        });
    } else {
        this.props.showMessageBar({
          text: this.props.whiteLabel.labels['Only 3 Phone Numbers'],
          status: 'error'
        });
    }
  }

  /**
   * Handler when a email has been added successfully.
   */
  handleAddEmailSuccess = () => {
    this.closeModal();
    this.fetchInitialState();
    this.props.showMessageBar({
      status: 'success',
      text: this.props.whiteLabel.labels['Email address added']
    });
  }

  /**
   * Handler for when to add an email has failed.
   */
  handleAddEmailError = (message) => {
    this.closeModal();
    this.props.showMessageBar({
      status: 'error',
      text: message
    });
  }

  /**
   * Handler when a phone has been added successfully.
   */
  handleAddPhoneSuccess = (d,values) => {
      this.closeModal();
      this.fetchInitialState();

        resendOtpCode(null, values.phone)
          .then((response) => {
              let data = response.data;
          
              if (
                data.status === 1 
                && data.statusCode === 200
              ) {
                this.props.showMessageBar({
                    status: 'success',
                    text: data.message
                });
                
                //Process when the number needs to be validated to add to the database
                  this.setState({
                    phoneToVerify: values.phone,
                    isModalOpen: true,
                    modal: 'VERIFY_PHONE'
                });
              } else {
                this.props.showMessageBar({
                    status: 'error',
                    text: data.message
                });
              }
          })
          .catch(() => {
              this.showInternalError();
          });
      
  }

  /**
   * Handler for when to add an phone has failed.
   */
  handleAddPhoneError = (message) => {
    this.closeModal();
    this.props.showMessageBar({
      status: 'error',
      text: message
    });
  }

  /**
   * Verify Phone Number with OTP success handler
   */
  handleVerifyPhoneSuccess = () => {
      //Process when the number needs to be validated to add to the database
      this.props.showMessageBar({
            status: 'success',
            text: this.props.whiteLabel.labels['Phone number added']
        });

      this.closeModal();
      this.fetchInitialState();
  }
  /**
   * Verify Phone Number with OTP error handler
   */
  handleVerifyPhoneError = (error) => {
    
  }


  /**
   * Remove a email from the user account.
   *
   * @param {number} id The email id.
   */
  removeEmail = (id) => {
      this.props.showLoading();
      if (
          this.state.details != undefined 
          && this.state.details.installEmailAddresses.length == 1 
          && this.state.details.installEmailAddresses[0].isPrimary
      ) {
          this.props.showMessageBar({
              text: this.props.whiteLabel.labels['Phone Email Instructions'],
              status: 'error'
          });
          this.props.hideLoading();
      } else {
          requestDeleteEmail(id)
          .then((response) => {
              this.props.hideLoading();

              let data = response.data;
              let status = 'error';

              if (data.status === 1 && data.statusCode === 200) {
                status = 'success';
              }

              this.props.showMessageBar({
                text: data.message,
                status
              });

              this.fetchInitialState();
          })
          .catch(() => {
              this.props.hideLoading();
              this.showInternalError();
          });
      }
  }

  /**
   * Setup a email as primary for a loan account.
   *
   * @param {number} id The email id.
   */
  setEmailAsPrimary = (id) => {
    this.props.showLoading();

    let data = []
    //This is the process of the updation of the email address on NLS
    let loanAccountEmail = this.getSelectedAccount();
    let emailAddr = ''
    this.state.details.installEmailAddresses.map(email => {
        if (email.id == id) {
            emailAddr = email.emailAddrText
        }
    })
    data = {
        id: loanAccountEmail.loan.id,
        email: emailAddr,
        emailId: id
    }

    requestMakeEmailAsPrimary(data)
    .then((response) => {
      this.props.hideLoading();

      let data = response.data;
      let status = 'error';

      if (data.status === 1 && data.statusCode === 200) {
          status = 'success';
      }

      this.props.showMessageBar({
        text: data.message,
        status
      });

      this.fetchInitialState();
    })
    .catch(() => {
      this.props.hideLoading();
      this.showInternalError();
    });
  }

  /**
   * Remove a phone from the user account.
   *
   * @param {number} id The phone id.
   */
  removePhone = (id) => {
    this.props.showLoading();
    if (this.state.details != undefined 
        && this.state.details.installMobilePhones.length == 1 
        && this.state.details.installMobilePhones[0].isPrimary
    ) {
        this.props.showMessageBar({
            text: this.props.whiteLabel.labels['Phone Email Instructions'],
            status: 'error'
        });
        this.props.hideLoading();
    } else {
        requestDeletePhone(id)
        .then((response) => {
            this.props.hideLoading();
      
            let data = response.data;
            let status = 'error';
      
            if (data.status === 1 && data.statusCode === 200) {
              status = 'success';
            }
      
            this.props.showMessageBar({
              text: data.message,
              status
            });
    
            this.fetchInitialState();
        })
        .catch(() => {
            this.props.hideLoading();
            this.showInternalError();
        });
    }
  }

  /**
   * Setup a phone as primary for a loan account.
   *
   * @param {number} id The phone id.
   */
  setPhoneAsPrimary = (id) => {
    this.props.showLoading();

    let data = []
    //This is the process of the updation of the phone number on NLS
    let loanAccountPhone = this.getSelectedAccount();
    let phoneNo = ''
    this.state.details.installMobilePhones.map(phone => {
        if (phone.id == id) {
            phoneNo = phone.mobilePhoneNo
        }
    })     
    data = {
        id: loanAccountPhone.loan.id,
        phoneno: phoneNo.replace("+", ""),
        phoneId: id
    }

    requestMakePhoneAsPrimary(data)
    .then((response) => {
      this.props.hideLoading();

      let data = response.data;
      let status = 'error';

      if (data.status === 1 && data.statusCode === 200) {
          status = 'success';
      }

      this.props.showMessageBar({
          text: data.message,
          status
      });

      this.fetchInitialState();
    })
    .catch(() => {
      this.props.hideLoading();
      this.showInternalError();
    });
  }

  /**
   * Display the resend email confirmation modal.
   * 
   * @param {number} id The email id.
   */
  showResendLinkModal = (event) => {
      if (typeof event == 'object') {
          this.selectedEmail = event.target.dataset.id;
      } else {
          this.selectedEmail = event
      }

      this.setState({
        isModalOpen: true,
        modal: 'RESEND_LINK'
      });
  }

  showResendOtpModal = (event) => {
      var phoneId = 0,
          phoneNumber = ''
      if (typeof event == 'object') {
          phoneId = event.target.dataset.id;
      } else {
          phoneId = event;
      }
      if (this.state.details.installMobilePhones != undefined) {
          this.state.details.installMobilePhones.map((phone, index) => {
              if (phone.id == phoneId) {
                  phoneNumber = phone.mobilePhoneNo
              }
          })
      }
      this.setState({
          phoneToVerify: phoneNumber,
          isModalOpen: true,
          modal: 'VERIFY_PHONE'
      });
  }

  /**
   * Resend the link to email confirmation.
   */
  resendLink = () => {
    this.props.showLoading();

    requestResendEmailConfirmation(this.selectedEmail)
      .then((response) => {
        this.props.hideLoading();

        let data = response.data;
        let status = 'error';

        if (data.status === 1 && data.statusCode === 200) {
          status = 'success';
        }

        this.closeModal();

        this.props.showMessageBar({
          text: data.message,
          status
        });
      })
      .catch(() => {
        this.closeModal();
        this.props.hideLoading();
        this.showInternalError();
      });
  }

  /**
   * Show the edit contact information modal.
   */
  showEditContactInformationModal = () => {
    this.setState({
      isModalOpen: true,
      modal: 'EDIT_CONTACT_INFORMATION'
    });
  }

  /**
   * Handler when contact information has been edit successfully.
   */
  handleEditSuccess = (message) => {
    this.closeModal();
    this.fetchInitialState();
    this.props.showMessageBar({
      status: 'success',
      text: message
    });
  }

  /**
   * Handler when edit contact information has failed.
   */
  handleEditError = (message) => {
    this.closeModal();
    this.props.showMessageBar({
      status: 'error',
      text: message
    });
  }

  /**
   * Handle the event when the user change the loan account.
   * 
   * @param event
   */
  handleChangeLoanAccount = (event) => {
    this.setState({
      selected: event.target.selectedIndex
    });
  }

  /**
   * Render the menu options for each email.
   */
  renderEmailOptions(id, isVerified, isPrimary) {
      var primary = ''
      if (isPrimary) {
          primary = <span className="email-list__primary">{this.props.whiteLabel.labels['Primary']}</span>
      }
      return (
          <div>
              {primary}
              {(this.props.hideAddEmailAddressBp !== undefined && this.props.hideAddEmailAddressBp != '1') ?
                <Dropdown alignToRight={true} className="options-dropdown" handler={() =>
                    <i className="fas fa-ellipsis-v icon--20px options-dropdown__handler"></i>
                }>
                  <DropdownItem className="options-dropdown__item" value={id} onClick={isVerified ? this.setEmailAsPrimary : this.showResendLinkModal}>
                  {this.props.whiteLabel.labels['Make Primary Email']}
                  </DropdownItem>
                  <DropdownItem className="options-dropdown__item" value={id} onClick={this.removeEmail}>
                  {this.props.whiteLabel.labels['Remove Email']}
                  </DropdownItem>
                </Dropdown>
              : null}
          </div>
      )
  }

  /**
   * Render the menu options for each phone.
   */
  renderPhoneOptions(id, isVerified, isPrimary) {
    var primary = ''
    if (isPrimary) {
        primary = <span className="email-list__primary">{this.props.whiteLabel.labels['Primary']}</span>
    }
    return (
      <div>
          {primary}
          {(this.props.hideAddPhoneNumberBp !== undefined && this.props.hideAddPhoneNumberBp != '1') ?
            <Dropdown alignToRight={true} className="options-dropdown" handler={() =>
                <i className="fas fa-ellipsis-v icon--20px options-dropdown__handler"></i>
            }>
                <DropdownItem className="options-dropdown__item" value={id} onClick={isVerified ? this.setPhoneAsPrimary : this.showResendOtpModal}>
                    {this.props.whiteLabel.labels['Make Primary Phone']}
                </DropdownItem>
                <DropdownItem className="options-dropdown__item" value={id} onClick={this.removePhone}>
                    {this.props.whiteLabel.labels['Remove Phone']}
                </DropdownItem>
            </Dropdown>
          : null}
      </div>
    )
  }

  renderModalContent() {
    let cifNumber = ''
    switch (this.state.modal) {
      case 'ADD_EMAIL':
        let loanAccountEmail = this.getSelectedAccount();
        return <AddEmailModal
            loanId={loanAccountEmail.loan.id}
            showLoading={this.props.showLoading}
            hideLoading={this.props.hideLoading}
            onSubmit={this.handleAddEmail}
            onSuccess={this.handleAddEmailSuccess}
            onError={this.handleAddEmailError}
            whiteLabel={this.props.whiteLabel} />
      case 'ADD_PHONE':
        return <AddPhoneModal
            showLoading={this.props.showLoading}
            hideLoading={this.props.hideLoading}
            whiteLabel={this.props.whiteLabel}
            onSubmit={this.handleAddPhone}
            onSuccess={this.handleAddPhoneSuccess}
            onError={this.handleAddPhoneError} />
      case 'VERIFY_PHONE':
        let loanAccountPhone = this.getSelectedAccount();
        return <VerifyPhone
            loanId={loanAccountPhone.loan.id}
            phoneNumber={this.state.phoneToVerify}
            email={this.state.emailToVerify}
            showLoading={this.props.showLoading}
            hideLoading={this.props.hideLoading}
            onSuccess={this.handleVerifyPhoneSuccess}
            onError={this.handleVerifyPhoneError} />
      case 'RESEND_LINK':
        return <ResendEmailVerification
          onCancel={this.closeModal}
          onResendLink={this.resendLink}
          whiteLabel={this.props.whiteLabel} />
      case 'EDIT_CONTACT_INFORMATION':
        let loanAccount = this.getSelectedAccount();
        let editContactInformationComponent;
        if (loanAccount.isBorrower === 1) {
          editContactInformationComponent = <EditContactInformation
            loanId={loanAccount.id}
            isBorrower={loanAccount.isBorrower}
            loanNumber={loanAccount.loan.loanNo}
            streetAddress={loanAccount.loan.borrower.streetaddress1}
            unitNumber={loanAccount.loan.borrower.streetaddress2}
            state={loanAccount.loan.borrower.state}
            city={loanAccount.loan.borrower.city}
            zipcode={loanAccount.loan.borrower.zip}
            states={this.props.states}
            showLoading={this.props.showLoading}
            hideLoading={this.props.hideLoading}
            onSuccess={this.handleEditSuccess}
            onError={this.handleEditError}
            whiteLabel={this.props.whiteLabel} />
        } else {
          editContactInformationComponent = <EditContactInformation
            loanId={loanAccount.id}
            isBorrower={loanAccount.isBorrower}
            loanNumber={loanAccount.loan.loanNo}
            streetAddress={loanAccount.loan.borrower.coBorrower.streetaddress1}
            unitNumber={loanAccount.loan.borrower.coBorrower.streetaddress2}
            state={loanAccount.loan.borrower.coBorrower.state}
            city={loanAccount.loan.borrower.coBorrower.city}
            zipcode={loanAccount.loan.borrower.coBorrower.zip}
            states={this.props.states}
            showLoading={this.props.showLoading}
            hideLoading={this.props.hideLoading}
            onSuccess={this.handleEditSuccess}
            onError={this.handleEditError}
            whiteLabel={this.props.whiteLabel} />
        }
        return editContactInformationComponent;
      default:
        return null;
    }
  }

  /**
   * Render the component view.
   */
  render() {
    let { handleSubmit } = this.props;
    let { details, loanAccounts, selected } = this.state;
    let frm;

    if (selected === null || !details) {
      return null;
    }

    let selectedLoanAccount = this.getSelectedAccount();

    if (selectedLoanAccount.isBorrower === 1) {
      frm = <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="form-group__label">{this.props.whiteLabel.labels['Street Number and Name']}</label>
          <input className="form-group__input" type="text" disabled
            value={selectedLoanAccount.loan.borrower.streetaddress1} />
        </div>

        <div>
          <div className="half-responsive">
            <div className="form-group">
              <label className="form-group__label">{this.props.whiteLabel.labels['Unit Number']}</label>
              <input className="form-group__input" type="text" disabled
                value={selectedLoanAccount.loan.borrower.streetaddress2} />
            </div>
          </div>
          <div className="half-responsive">
            <div className="form-group">
              <label className="form-group__label">{this.props.whiteLabel.labels['City']}</label>
              <input className="form-group__input" type="text" disabled
                value={selectedLoanAccount.loan.borrower.city} />
            </div>
          </div>
        </div>

        <div>
          <div className="half-responsive">
            <div className="form-group">
              <label className="form-group__label">{this.props.whiteLabel.labels['State']}</label>
              <input className="form-group__input" type="text"
                value={selectedLoanAccount.loan.borrower.state} disabled />
            </div>
          </div>
          <div className="half-responsive">
            <div className="form-group">
              <label className="form-group__label">{this.props.whiteLabel.labels['Zipcode']}</label>
              <input className="form-group__input" type="text"
                value={selectedLoanAccount.loan.borrower.zip} disabled />
            </div>
          </div>
        </div>
      </form>
    } else {
      frm = <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="form-group__label">{this.props.whiteLabel.labels['Street Number and Name']}</label>
          <input className="form-group__input" type="text" disabled
            value={selectedLoanAccount.loan.borrower.coBorrower.streetaddress1} />
        </div>

        <div>
          <div className="half-responsive">
            <div className="form-group">
              <label className="form-group__label">{this.props.whiteLabel.labels['Unit Number']}</label>
              <input className="form-group__input" type="text" disabled
                value={selectedLoanAccount.loan.borrower.coBorrower.streetaddress2} />
            </div>
          </div>
          <div className="half-responsive">
            <div className="form-group">
              <label className="form-group__label">{this.props.whiteLabel.labels['City']}</label>
              <input className="form-group__input" type="text" disabled
                value={selectedLoanAccount.loan.borrower.coBorrower.city} />
            </div>
          </div>
        </div>

        <div>
          <div className="half-responsive">
            <div className="form-group">
              <label className="form-group__label">{this.props.whiteLabel.labels['State']}</label>
              <input className="form-group__input" type="text"
                value={selectedLoanAccount.loan.borrower.coBorrower.state} disabled />
            </div>
          </div>
          <div className="half-responsive">
            <div className="form-group">
              <label className="form-group__label">{this.props.whiteLabel.labels['Zipcode']}</label>
              <input className="form-group__input" type="text"
                value={selectedLoanAccount.loan.borrower.coBorrower.zip} disabled />
            </div>
          </div>
        </div>
      </form>
    }

    return (
      <React.Fragment>
        <div className="flex-block flex-block--space-between">
          <h2 className="settings-card__title">{this.props.whiteLabel.labels['Contact Information']}</h2>
          {(!this.props.updateAllCifAddresses) && 
            <div className="contact-info__loan-accounts">
            <label className="contact-info__loan-accounts-label">{this.props.whiteLabel.labels['Select Account']}</label>
            <select
              className="custom-select"
              value={selectedLoanAccount.id}
              onChange={this.handleChangeLoanAccount}>

              {loanAccounts.map((loanAccount) =>
                <option key={loanAccount.id} value={loanAccount.id}>
                  {loanAccount.loan.loanNo}
                </option>
              )}
            </select>
          </div> }
        </div>

        <hr className="hr" />

        <div className="contact-info__edit-button">
          {
            (this.props.updateAllCifAddresses || this.props.isNlsEnable) && (
              <PayixButton type="button" onClick={this.showEditContactInformationModal}>
                  {this.props.whiteLabel.labels['Edit']}
              </PayixButton>
            )
          }
        </div>

        {frm}

        <p>{this.props.whiteLabel.labels['EMAIL ADDRESSES']}</p>
        <ul className="email-list">
          {details.installEmailAddresses.map(email =>
              <li className="email-list__item" key={email.id}>
                  <span>
                    {email.emailAddrText}&nbsp;
                    {!email.isVerified
                        ? <i className="fas fa-exclamation-triangle icon--red" data-id={email.id}
                            onClick={this.showResendLinkModal}>
                        </i>
                        : <i className="far fa-check-circle color-green"></i>
                    }
                  </span>
                  {this.renderEmailOptions(email.id, email.isVerified, email.isPrimary)}
              </li>
          )}

          {(details.installEmailAddresses.length < 5 && this.props.hideAddEmailAddressBp !== undefined && this.props.hideAddEmailAddressBp != '1')
          && (
              <li className="email-list__item color-green default-cursor">
                <span onClick={this.showAddEmailModal}>
                  <i className="fas fa-plus-circle"></i>  {this.props.whiteLabel.labels['Add Email Address']}
              </span>
              </li>
          )
          }
        </ul>

        <p>{this.props.whiteLabel.labels['Phone Numbers']}</p>
        <ul className="email-list">
          {(details.installMobilePhones != undefined) ? details.installMobilePhones.map(phone =>
              <li className="email-list__item" key={phone.id}>
                  <span>
                    {phone.mobilePhoneNo}&nbsp;
                    {!phone.isVerified
                        ? <i className="fas fa-exclamation-triangle icon--red" data-id={phone.id}
                            onClick={this.showResendOtpModal}>
                        </i>
                        : <i className="far fa-check-circle color-green"></i>
                    }
                  </span>
                  {this.renderPhoneOptions(phone.id, phone.isVerified, phone.isPrimary)}
              </li>
          ) : ''}

          {(details.installMobilePhones != undefined && this.props.hideAddPhoneNumberBp !== undefined && this.props.hideAddPhoneNumberBp != '1') ? /*(details.installMobilePhones.length < 3)
          && (*/
              <li className="email-list__item color-green default-cursor">
                <span onClick={this.showAddPhoneModal}>
                  <i className="fas fa-plus-circle"></i> {this.props.whiteLabel.labels['Add Mobile Phone']}
              </span>
              </li>
          /*)*/
           : ''}
        </ul>

        <Modal
          isOpen={this.state.isModalOpen}
          onClose={this.closeModal}>
          {this.renderModalContent()}
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    states: state.auth.states,
    isNlsEnable: state.client.data.nlsPostProfile,
    updateAllCifAddresses: state.client.data.updateAllCifAddresses,
    hideAddEmailAddressBp: state.client.data.hideAddEmailAddressBp,
    hideAddPhoneNumberBp: state.client.data.hideAddPhoneNumberBp,
    whiteLabel: state.whiteLabel
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getStates, showLoading, hideLoading, showMessageBar
  }, dispatch);
}

ContactInformation = reduxForm({
  form: 'editContactInformationForm'
})(ContactInformation)

export default connect(mapStateToProps, mapDispatchToProps)(ContactInformation);