import React from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import moment from 'moment';

const minDate = new Date();
const lang = localStorage.getItem('language');
const NUMBER_OF_YEARS_OPTIONS = 80;
import('date-fns/esm/locale').then((response) => {
  const allLangs = Object.keys(response);
  allLangs.forEach((currentLang) => {
    // Registering all the languages for datepicker
    registerLocale(currentLang, response[currentLang]); 
  });
  if (response[lang]) {
    // Setting default locale
    setDefaultLocale(lang);
  } 
});

const Datefield = ({input, label, htmlFor}) => (
  <div className="form-group">
    <label htmlFor={htmlFor} className="form-group__label">
      {label}
    </label>
    <DatePicker
      onChange={input.onChange}
      selected={input.value ? input.value : null}
      maxDate={minDate}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      className="datepicker"
      dateFormat="MM/dd/yyyy"
      yearDropdownItemNumber={NUMBER_OF_YEARS_OPTIONS}
      scrollableYearDropdown
    />
    
  </div>
);

export const DatefieldMin18 = ({input, label, htmlFor}) => (
  <div className="form-group">
    <label htmlFor={htmlFor} className="form-group__label">
      {label}
    </label>
    <DatePicker
      selected={input.value ? input.value : null}
      onChange={input.onChange}
      minDate={new Date(moment().subtract(500, "years"))}
      maxDate={new Date(moment().subtract(18, "years"))}
      showMonthDropdown
      showYearDropdown
      className="datepicker"
      dateFormat="MM/dd/yyyy"
      yearDropdownItemNumber={NUMBER_OF_YEARS_OPTIONS}
      scrollableYearDropdown
    /> 
  </div>
);

export default Datefield;