import React, { Component } from 'react';
import { Application } from '../core/app';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addCard, addAch } from '../actions/client';

// Import components
import AddCardForm from './add-card-form';
import AddAchForm from './add-ach-form';
import AddPadForm from './add-pad-form';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// Import styles
import 'react-tabs/style/react-tabs.css';
class AddPaymentMethodModal extends Component {
  state = {
    title: '',
    description: ''
  }

  componentDidMount() {
    if((this.props.countryCode === 'CA' ? this.props.enableAch : false)){
      this.setPadFormDescription();
    } else if (this.props.enableCard) {
      this.setCardFormDescription();
    } else {
      this.setAchFormDescription();
    }
  }

  /**
   * Set the title and description on card ach.
   */
  setCardFormDescription() {
    this.setState({
      title: this.props.whiteLabel.labels['Add Card Title'],
      description: this.props.whiteLabel.labels['Add Card Description']
    });
  }

  /**
   * Set the title and description on create ach.
   */
  setAchFormDescription() {
    this.setState({
      title: this.props.whiteLabel.labels['Add Bank Account Title'],
      description: this.props.whiteLabel.labels['Add Bank Account Description']
    });
  }

  /**
   * Set the title and description on create pad.
   */
  setPadFormDescription() {
    this.setState({
      title: this.props.whiteLabel.labels['Add Bank Account (CA) Title'],
      description: this.props.whiteLabel.labels['Add Bank Account (CA) Description']
    });
  }

  /**
   * Controls the event each time the tab is changed.
   */
  handleSelectedTab = (index) => {
    if (index === 0 && (this.props.countryCode === 'CA' ? this.props.enableAch : false)) {
      this.setPadFormDescription();
    } else if (index === 0) {
      this.setCardFormDescription();
    } else if (index === 1) {
      this.setAchFormDescription();
    } else if (index === 2) {
      this.setPadFormDescription();
    }
  }

  /**
   * Add a new card.
   */
  addCard = (values) => {
    values.expiryDate = values.expirationMonth + '/' + values.expirationYear;

    this.props.addCard(values)    
      .then((response) => {
        let data = response.data;

        if (data.status === 1) {
          this.props.onSuccess();
          //It needs to be checked if the props method exists, to do the change payment process
          if (this.props.changePayment) {
            this.props.changePayment(0, true);
          }
        }
      })
      .catch(() => {
        Application.showInternalError();
      })
  }

  /**
   * Add a new ach account.
   */
  addAch = (values) => {
    this.props.addAch(values)    
      .then((response) => {
        let data = response.data;

        if (data.status === 1) {
          this.props.onSuccess();
          //It needs to be checked if the props method exists, to do the change payment process
          if (this.props.changePayment) {
            this.props.changePayment(0, true);
          }
        }
      })
      .catch(() => {
        Application.showInternalError();
      })
  }

  /**
   * Render the component view.
   */
  render() { 
    let enableCard = this.props.enableCard ? true : false;
    return (  
      <div>
        <div className="modal__header">
          <div className="modal__note-wrapper">
            <h3 className="payment-methods__title">{ this.state.title }</h3>
            <p className="payment-methods__description">{ this.state.description }</p>
          </div>
        </div>

        <div className="modal__body">
          <Tabs onSelect={ this.handleSelectedTab }>
            <TabList>
              {(this.props.countryCode === 'CA' ? this.props.enableCardCanada : enableCard) && (<Tab>{this.props.whiteLabel.labels['CARD']}</Tab>)}
              {(this.props.countryCode === 'CA' ? false : this.props.enableAch) && (<Tab>{this.props.whiteLabel.labels['Bank Account']}</Tab>)}
              {(this.props.countryCode === 'CA' ? this.props.enableAch : false) && (<Tab>{this.props.whiteLabel.labels['Bank Account (CA)']}</Tab>)}
            </TabList>

            {/* Card Form */}
            {(this.props.countryCode === 'CA' ? this.props.enableCardCanada : enableCard)
              && (
                <TabPanel>
                  <AddCardForm onSubmit={ this.addCard } whiteLabel={this.props.whiteLabel} />
                </TabPanel>
              )
            }

            {/* ACH Form */}
            {(this.props.countryCode === 'CA' ? false : this.props.enableAch) 
              && (
                <TabPanel>
                  <AddAchForm onSubmit={ this.addAch } whiteLabel={this.props.whiteLabel} />
                </TabPanel>
              )
            }

            {/* PAD Form */}
            {(this.props.countryCode === 'CA' ? this.props.enableAch : false) 
              && (
                <TabPanel>
                  <AddPadForm onSubmit={ this.addAch } whiteLabel={this.props.whiteLabel} />
                </TabPanel>
              )
            }

          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    enableAch: state.client.data.isAchMobile,
    enableCardCanada: state.client.data.hideCardIfCa,
    enableCard: state.client.data.cardEnable,
    countryCode: state.auth.userData.tblState ? state.auth.userData.tblState.countryCode : 'US',
    whiteLabel: state.whiteLabel
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    addCard, addAch
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentMethodModal);