import React, { useEffect } from 'react';
import '../../styles/layout/microfrontend.css';

export default function MicroFrontEnd({ name, host, config }) {
  const renderMicroFrontend = () => {
    window[`render${name}`](`${name}-container`, config);
  };

  useEffect(() => {
    const scriptId = `micro-frontend-script-${name}`;

    if (document.getElementById(scriptId)) {
      renderMicroFrontend();
    } else {
      fetch(`${host}/asset-manifest.json`)
      .then((res) => res.json())
      .then((manifest) => {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = `${host}${manifest.files['main.js']}`;
        script.onload = renderMicroFrontend;
        document.head.appendChild(script);
      });
    }

    
    return () => {
      window[`unmount${name}`](`${name}-container`);
    };
  }, []);

  return <div className="microfrontend" id={`${name}-container`} />;
}
