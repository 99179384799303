import React from 'react'
import PMS from '../../components/microfrontend/PMS/PMS';

export default function ChatPMS() {
  return (
    <div className="chat">
      <PMS />
    </div>
  )
}
