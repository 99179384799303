import React from 'react';
import MicroFrontEnd from '../microfrontend';
import { connect } from 'react-redux';

function PMS({ pms, userData, chatMaxCharacter }) {
  if (!pms || !userData || !chatMaxCharacter) return null;

  return (
    <MicroFrontEnd
      name="PMS"
      host={(pms.pmsUrl).replace(/app\./g, '')}
      config={{
        username: userData.pmsId,
        clientId: pms.pmsUuid,
        clientSecret: pms.pmsSecret,
        inputMaxCharacters: chatMaxCharacter,
        baseApiUrl: pms.pmsUrl
      }}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    pms: state.client.data.pms,
    chatMaxCharacter: state.client.data.chatMaxCharacter,
    userData: state.auth.userData
  }
}

export default connect(mapStateToProps, {})(PMS);
