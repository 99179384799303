import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoanAccountService from '../../services/loan-account';
import { userLogOut } from '../../actions/auth';
import { withRouter } from 'react-router-dom';
import { Application } from '../../core/app';
import { apiTermsNConditions } from '../../api/auth';
import { findById } from '../../helpers/app';
import { usersUpdatePmsPushable } from '../../api/client'

import IdleTimer from 'react-idle-timer'; 

// Import actions
import { hideMessageBar, showLoading, hideLoading, showMessageBar, } from '../../actions/app';
import { setAuthUserData } from '../../actions/auth';
import { getClientDetails, getClientImages, getPaymentFrequencies } from '../../actions/client';
import { getWhiteLabelsAction } from '../../actions/white-label';
import { getLoanAccountsInfo } from '../../api/loan-account';

// Import components
import AppHeader from '../../components/application/app-header';
import AppFooter from '../../components/application/app-footer';
import InfoModal from '../../components/application/modals/info-modal';
import Modal from '../../components/application/modal';
import MsgBar from '../../components/application/msg-bar';
import Notice from '../../components/application/notice';
import TermsConditions from '../../components/application/modals/terms-conditions';
import Timeout from '../../components/application/modals/timeout';
import UdfDisclosureModal from '../../components/udf-disclosure-modal';

// Import styles
import 'animate.css/animate.css';
import '../../styles/base/admin-panel.css';
import '../../styles/layout/admin-panel.css';
import '../../styles/modules/header.css';
import '../../styles/modules/footer.css';
import '../../styles/modules/page-options.css';
import '../../styles/modules/widgets.css';
import '../../styles/modules/custom-widgets/modal.css';
import 'react-datepicker/dist/react-datepicker.css';

class AdminPanel extends Component {
  constructor(props){
    super(props);
    this.state = {
      isModalOpen: false,
      modal: null,
      modalWidth: '500px',
      lastScheduledPaymentDeleted: null,
      isNoticeModalOpen: false,
      terms: '',
      notice: '',
      timeout: 900000,
      isTimedOut: false,
      selectedLanguage: 'en',
      details: 0
    }
  
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  /**
   * Fetch the initial data.
   */
  componentDidMount() {
    let data = Application.getAuthUserData();
    this.props.setAuthUserData(data);
    let language = JSON.parse(localStorage.getItem('userData'))['languageCode'] || 'en';
    this.setState({
      selectedLanguage: language
    })
    Promise.all([
        this.props.getPaymentFrequencies(), 
        this.props.getWhiteLabelsAction(language)
      ]);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedLoanAccount !== this.props.selectedLoanAccount) {
      this.props.showLoading();
      Promise.all([
        this.props.getClientDetails(this.props.selectedLoanAccount.loanNo),
        this.props.getClientImages(this.props.selectedLoanAccount.loanNo)
      ])
      .then(() => {
        this.props.hideLoading();
        document.title = this.props.client.appName;
        this.setState({timeout: 60000 * this.props.client.sessionTimeout});
      })
      .catch(() => {
        this.props.hideLoading();
        Application.showInternalError();
      });
    } else {
      if (!this.state.details) {
        this.props.showLoading();
        this.setState({details: 1});
        getLoanAccountsInfo()
        .then((response) => {
          let data = response.data;
          if (data.status === 1 && data.statusCode === 200) {
            let loans = data.result.loans;
            if (loans.length > 0) {
              Promise.all([
                this.props.getClientDetails(loans[0].loan.loanNo),
                this.props.getClientImages(loans[0].loan.loanNo)
              ])
              .then(() => {
                this.props.hideLoading();
                document.title = this.props.client.appName;
                this.setState({timeout: 60000 * this.props.client.sessionTimeout});
              })
              .catch(() => {
                this.props.hideLoading();
                Application.showInternalError();
              });
            }
          } else {
            this.props.hideLoading();
            this.props.showMessageBar({
              status: 'error',
              text: data.message
            });
          }
        })
        .catch(() => {
          this.props.hideLoading();
          Application.showInternalError();
        });
      }
    }
  }

  changeLanguage(code) {  
    this.props.getWhiteLabelsAction(code);
    localStorage.setItem('language', code)
  }

  /**
   * Display or hidden a specific modal.
   * 
   * @param modalId
   */
  toggleModal(modalId) {
    this.setState((prevState) => {
      let key = `is${modalId}ModalOpen`;
      let newState = {};

      newState[key] = !prevState[key];

      return newState;
    });
  }

  /**
   * Close the modal opened.
   */
  closeModal = () => {
    this.setState({
      isModalOpen: false,
      modal: null,
      modalWidth: '500px'
    });
  }

  /**
   * Open or hidden the notice for show a new notice.
   * 
   * @param message - The message to show.
   */
  handleNoticeModal = (message) => {
    this.setState({ notice: message }, () => {
      this.toggleModal('Notice');
    });
  }

  /**
   * Show or hidden the notice modal.
   */
  toggleNoticeModal = () => {
    this.toggleModal('Notice');
  }

  /**
   * Handle the event when msg bar is dismissed.
   */
  handleDismissMsgBar = () => {
    this.props.hideMessageBar()
  }

  /**
   * Display a modal to confirm the logout.
   */
  handleLogout = () => {
    this.setState({
      isModalOpen: true,
      modal: 'LOGOUT'
    });
  }

  /**
   * Display a modal for timeout.
   */
  handleTimeout = () => {
    this.setState({
      isModalOpen: true,
      modal: 'TIMEOUT'
    });
  }

  /**
   * Logout the current user.
   * 
   */
  logout = () => {
    this.setState({
      isModalOpen: false,
      modal: null
    }, () => {
      //Change the PMS pushable of the user when logout to true, because it's no longer looking at the chat
      usersUpdatePmsPushable(true)
        .then(response => {
        })
        .catch((err) => {
        });
      this.props.userLogOut();
    });
  }

  /**
   * Display a modal with the terms and conditions.
   */
  displayTerms = () => {
    let termsBodyRegex = /<body>([\S\s]+)<\/body>/m;
    let userData = this.props.userData;

    this.props.showLoading();

    apiTermsNConditions(userData.tblState ? userData.tblState.id : '')
      .then((response) => {
        this.props.hideLoading();

        if (!response) {
          return false;
        }

        const { data } = response;
        const body = data.match(termsBodyRegex)[1];

        this.setState({
          isModalOpen: true,
          modal: 'TERMS_CONDITIONS',
          modalWidth: '1150px',
          terms: body
        });
      })
      .catch(() => {
        this.props.hideLoading();
      });
  }

  /**
   * Render the indicate content for the requested modal.
   */
  renderModalContent() {
    switch (this.state.modal) {
      case 'TERMS_CONDITIONS':
        return <TermsConditions
          onClose={this.closeModal} 
          terms={this.state.terms}
          whiteLabel={this.props.whiteLabel}
          />;
      case 'LOGOUT':
        return <InfoModal
          content={this.props.whiteLabel.labels['Are you sure you want to logout?']}
          leftButtonHandler={this.closeModal}
          rightButtonHandler={this.logout}
          whiteLabel={this.props.whiteLabel} />;
      case 'TIMEOUT':
        this.idleTimer.pause();
        return <Timeout
          idleTimer={this.idleTimer}
          contentTitle={this.props.whiteLabel.labels['Session Timeout Title']}
          content={this.props.whiteLabel.labels['Session Timeout Message']}
          whiteLabel={this.props.whiteLabel} 
          closeModal={this.closeModal}
          logoutAction={this.logout} />;
      default:
        return null;
    }
  }

  _onAction(e) {
    this.setState({isTimedOut: false})
  }

  _onActive(e) {
    this.setState({isTimedOut: false})
  }

  _onIdle(e) {
    this.handleTimeout();
    this.idleTimer.reset();
    this.setState({isTimedOut: true})
  }

  /**
   * Render the component view.
   */
  render() {
    const { client } = this.props;
    const activeTab = this.props.location.pathname.split('/').pop();
    
    return (
      <div className="admin-panel">
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          element={document}
          debounce={250}
          timeout={this.state.timeout} />
          
        <MsgBar
          text={this.props.message.text}
          status={this.props.message.status}
          onDismiss={this.handleDismissMsgBar} />

        <AppHeader
          onLogout={this.handleLogout}
          activeTab={activeTab}
          logo={client.imageName}
          color={client.colorCode}
          hideBpContactUs={client.hideBpContactUs}
          statements={client.statements}
          languages={client.languages}
          changeLanguage={this.changeLanguage}
          selectedLanguage={this.state.selectedLanguage}
          whiteLabel={this.props.whiteLabel}
          company={{
            addressApptNum: client.addressApptNum,
            addressStreet: client.addressStreet,
            addressCity: client.addressCity,
            addressState: client.addressState,
            addressZip: client.addressZip,
            phoneNumber: client.phoneNumber,
            website: client.website,
            customerCareEmail: client.customerCareEmail
          }} />

        <div className="content-wrapper">
          {this.props.children}
        </div>

        <AppFooter
          customUrl1={this.props.client.clientCustomUrl1 != '' ? this.props.client.clientCustomUrl1 : ''}
          customUrl1Text={this.props.client.clientCustomUrl1Text != '' ? this.props.client.clientCustomUrl1Text : ''}
          customUrl2={this.props.client.clientCustomUrl2 != '' ? this.props.client.clientCustomUrl2 : ''}
          customUrl2Text={this.props.client.clientCustomUrl2Text != '' ? this.props.client.clientCustomUrl2Text : ''}
          onShowTerms={this.displayTerms}
          whiteLabel={this.props.whiteLabel}
          privacyPolicy={this.props.client.privacyPolicy}
        />

        {/**
          ** Notice Modal
          **/}
        <Modal
          isOpen={this.state.isNoticeModalOpen}
          onClose={() => {
            this.toggleModal('Notice');
          }}>
          <Notice
            message={this.props.notice}
            onClose={this.toggleNoticeModal}
            whiteLabel={this.props.whiteLabel} />
        </Modal>

        <Modal
          isOpen={this.state.isModalOpen}
          width={this.state.modalWidth}
          onClose={this.closeModal}>
          {this.renderModalContent()}
        </Modal>
        <UdfDisclosureModal />
      </div>
    );
  }
}

const loanAccountService = new LoanAccountService();

const mapStateToProps = state => {
  let paymentMethod = findById(state.app.payment.selectedMethod, state.client.cards);

  let props = {
    loanAccounts: state.app.loanAccounts,
    selectedLoanAccount: loanAccountService.get(state.app.selectedLoanAccount),
    notice: state.app.notice,
    amount: state.app.payment.amount,
    client: state.client.data,
    message: state.app.message,
    modal: state.app.modal,
    userData: state.auth.userData,
    paymentMethod,
    whiteLabel : state.whiteLabel
  };

  // Pass the payment details if exists.
  if (state.app.payment.details) {
    props.paymentDetails = state.app.payment.details;
  }

  // Pass the link loan account form data if exists.
  let loanAccountLinkReduxForm = state.form.loanAccountLinkForm;

  if (loanAccountLinkReduxForm && loanAccountLinkReduxForm.values) {
    props.loanAccountLinkForm = loanAccountLinkReduxForm.values
  }

  return props;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    userLogOut, getClientDetails, getClientImages, getPaymentFrequencies, setAuthUserData, showLoading, hideLoading, hideMessageBar, getWhiteLabelsAction, showMessageBar
  }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminPanel));
