import moment from 'moment';

export function formatToSsn(value) {
  if (!value) {
    return '';
  }

  let f = value.toString();
  let val = f.replace(/\D/g, '');
  let newVal = '';
  let sizes = [3, 2, 4];
  for (var i in sizes) {
    if (val.length > sizes[i]) {
      newVal += val.substr(0, sizes[i]) + '-';
      val = val.substr(sizes[i]);
    } else {
      break;
    }
  }

  newVal += val;
  val = newVal;

  return val;
}

/**
 * Remove dashes to a string.
 */
export function removeDashes(value) {
  return value ? value.replace(/-/g, '') : '';
}

/**
 * Format a number value to USD.
 *
 * @param {*} value
 */
export function toUSD(value) {
  value = parseFloat(value);

  if (isNaN(value)) {
    value = 0;
  }
  
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });

  return formatter.format(value);
}

export function toDate(value) {
  return new Date(value).toLocaleDateString();
}

export function toCardNumber(value) {
  return 'xxxx-xxxx-xxxx-' + value;
}

export function toAchNumber(value) {
  return 'xxxxx' + value;
}

export function toAchType(value) {
  let typeText = (value === 1) ? 'Checking':'Saving';
  return '(' + typeText + ')';
}

export function checkDecimal(value) {
  let regex = /^[0-9]*\.?[0-9]{0,2}$/;

  return value.match(regex);
}

/**
 * Format a date and format it on Y-m-d format.
 */
export function toYmdDate(date) {
  let dateObj = new Date(date);
  let day = dateObj.getDate();

  let month = dateObj.getMonth() +1; 
  let year = dateObj.getFullYear();

  if (day < 10) {
    day = '0' + day;
  } 

  if (month < 10) {
    month = '0' + month;
  }

  return year + '-' + month + '-' + day;
}

export function dateWithFormatOrNa(date, format = 'MM/DD/YYYY') {
  return moment(date).format(format);
}