import React, { Component } from 'react';
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { toUSD } from '../../helpers/formatters';
import Tooltip from 'rc-tooltip';

import Dropdown from '../../components/application/dropdown/index';
import DropdownItem from '../../components/application/dropdown/item';
class ScheduleReceipt extends Component {
  state = {
    isOptionsDisplayed: false
  }
  
  constructor(props) {
    super(props);

    dayjs.extend(localeData);
    var language = localStorage.getItem('language')
    import(`dayjs/locale/${language}`)
    .then(() => {
        dayjs.locale(language);
        this.forceUpdate();
    });
  }

  /**
   * Get the stamp according to the status of the scheduled payment.
   */
  getStamp() {
    let stampTxt = 'SCHEDULED';
    if (this.props.isFinalSchedule === true && this.props.isFinal === true) {
      stampTxt = 'FINAL SCHEDULE';
    } else {
      if (this.props.status === '2') {
        stampTxt = 'DECLINED';
      } else if (this.props.status === '3') {
        stampTxt = 'DELETED';
      }
    }
    return stampTxt;
  }

  /**
   * Get the stamp color to the status of the scheduled payment.
   */
  getStampColor() {
    let stampColor = 'green';
    if (this.props.isFinalSchedule === true && this.props.isFinal === true) {
      stampColor = 'red';
    } else {
      if (this.props.status === '2') {
        stampColor = 'red';
      } else if (this.props.status === '3') {
        stampColor = 'black';
      }
    }
    return stampColor;
  }

  /**
   * Display or hidden the receipt options dropdown.
   */
  displayOptions = () => {
    let status = !this.state.isOptionsDisplayed;

    this.setState({
      isOptionsDisplayed: status
    });
  }

  /**
   * Handle the event to edit a specific scheduled payment.
   * 
   * @param id
   */
  handleEdit = (id) => {
    this.displayOptions();
    this.props.onEdit(id);
  }

  /**
   * Handle the event to edit a specific scheduled payment.
   * 
   * @param id
   */
  handleRecurringEdit = (id) => {
    this.displayOptions();
    this.props.onRecurringEdit(id);
  }

  /**
   * handle the event to delete a specific scheduled payment.
   * 
   * @param id
   */
  handleDelete = (id) => {
    this.displayOptions();
    this.props.onDelete(id);
  }


  handleRecurringDelete = (id) => {
    this.displayOptions();
    this.props.onRecurringDelete(id);
  }


  /**
   * Get the description for the frequency of the schedule.
   */
  getFrequency = () => {
    let frequency = this.props.frequency;
    let shortDescription = {
      recurring: '',
      freq: this.props.whiteLabel.labels['ONE TIME']
    };

    if (this.props.scheduleType !== '1') {
      let index = frequency.indexOf("|");

      if (index !== -1) {
        //shortDescription = frequency.substr(0, (index - 1));
        //shortDescription = 'Recurring'
        shortDescription = {
          recurring: 'Recurring',
          freq: frequency.substr(0, (index - 1))
        };
      }
    }

    return shortDescription;
  }

  /**
   * Get the Convenience Fee Block if it is not o
   */
  renderConvenienceFee = () => {
    return (
      <div className="receipt__data-section">
        <p className="receipt__value">{toUSD(this.props.convenienceFee)}</p>
        <span className="receipt__label">{this.props.whiteLabel.labels['Convenience Fee']}</span>
      </div>
    )
  }

  /**
   * Get the Final Tile Message from White Labels
   */
  renderFinalTileMsg = () => {
    return (
      <div className="info__sch_msg">
        <Tooltip placement="top" overlay={this.props.whiteLabel.labels['Final Tile Message']}>
          <button className="button button--small button--red">{this.props.whiteLabel.labels['LEARN MORE']} </button>
        </Tooltip>
      </div>
    )
  }

  renderOptions = () => {
    if ((this.props.status !== '0') && (this.props.status !== 'Active')) {
      return null
    }
    //change edit and delete off of this.props.can.edit and delete
    if(!this.props.can.edit && !this.props.can.delete){
      return (
        <div style={{right:'10px'}} className="receipt__options-wrapper text-center">
          {this.props.whiteLabel.labels['Call to Edit']}
        </div>
      )
    }else{
      if(this.props.recurringId){
        if(this.props.can.edit && this.props.can.delete){
          return (
            <div className="receipt__options-wrapper">
              <Dropdown alignToRight={true} className="options-dropdown" handler={() =>
                <i className="fas fa-ellipsis-v size-16 icon--20px color-gray"></i>
              }>
                <DropdownItem className="options-dropdown__item" value={this.props.id} onClick={this.handleEdit}>
                  {this.props.whiteLabel.labels['Edit One-Time Payment']}
                </DropdownItem>
                <DropdownItem className="options-dropdown__item" value={this.props.id} onClick={this.handleRecurringEdit}>
                  {this.props.whiteLabel.labels['Edit Recurring Schedule']}
                </DropdownItem>
                <DropdownItem className="options-dropdown__item" value={this.props.id} onClick={this.handleDelete}>
                 {this.props.whiteLabel.labels['Delete One-Time Payment']}
                </DropdownItem>
                <DropdownItem className="options-dropdown__item" value={this.props.id} onClick={this.handleRecurringDelete}>
                {this.props.whiteLabel.labels['Delete Recurring Schedule']}
                </DropdownItem>
              </Dropdown>
            </div>
          )
        }else if (this.props.can.edit && !this.props.can.delete){
          return (
            <div className="receipt__options-wrapper">
              <Dropdown alignToRight={true} className="options-dropdown" handler={() =>
                <i className="fas fa-ellipsis-v size-16 icon--20px color-gray"></i>
              }>
                <DropdownItem className="options-dropdown__item" value={this.props.id} onClick={this.handleEdit}>
                {this.props.whiteLabel.labels['Edit One-Time Payment']}
                </DropdownItem>
                <DropdownItem className="options-dropdown__item" value={this.props.id} onClick={this.handleRecurringEdit}>
                {this.props.whiteLabel.labels['Edit Recurring Schedule']}
                </DropdownItem>
              </Dropdown>
            </div>
          )
        }else if (this.props.can.delete && !this.props.can.edit){
          return (
            <div className="receipt__options-wrapper">
              <Dropdown alignToRight={true} className="options-dropdown" handler={() =>
                <i className="fas fa-ellipsis-v size-16 icon--20px color-gray"></i>
              }>
                <DropdownItem className="options-dropdown__item" value={this.props.id} onClick={this.handleDelete}>
                {this.props.whiteLabel.labels['Delete One-Time Schedule']}
                </DropdownItem>
                <DropdownItem className="options-dropdown__item" value={this.props.id} onClick={this.handleRecurringDelete}>
                {this.props.whiteLabel.labels['Delete Recurring Schedule']}
                </DropdownItem>
              </Dropdown>
            </div>
          )
        }
        
      }else{
        if (this.props.can.delete && this.props.can.edit){
          return (
            <div className="receipt__options-wrapper">
              <Dropdown alignToRight={true} className="options-dropdown" handler={() =>
                <i className="fas fa-ellipsis-v size-16 icon--20px color-gray"></i>
              }>
                <DropdownItem className="options-dropdown__item" value={this.props.id} onClick={this.handleEdit}>
                {this.props.whiteLabel.labels['Edit']}
                </DropdownItem>
                <DropdownItem className="options-dropdown__item" value={this.props.id} onClick={this.handleDelete}>
                {this.props.whiteLabel.labels['Delete']}
                </DropdownItem>
              </Dropdown>
            </div>
          )
        }else if (this.props.can.delete && !this.props.can.edit){
          return (
            <div className="receipt__options-wrapper">
              <Dropdown alignToRight={true} className="options-dropdown" handler={() =>
                <i className="fas fa-ellipsis-v size-16 icon--20px color-gray"></i>
              }>
                <DropdownItem className="options-dropdown__item" value={this.props.id} onClick={this.handleDelete}>
                {this.props.whiteLabel.labels['Delete']}
                </DropdownItem>
              </Dropdown>
            </div>
          )
        }else if (this.props.can.edit && !this.props.can.delete){
          return (
            <div className="receipt__options-wrapper">
              <Dropdown alignToRight={true} className="options-dropdown" handler={() =>
                <i className="fas fa-ellipsis-v size-16 icon--20px color-gray"></i>
              }>
                <DropdownItem className="options-dropdown__item" value={this.props.id} onClick={this.handleEdit}>
                {this.props.whiteLabel.labels['Edit']}
                </DropdownItem>
              </Dropdown>
            </div>
          )
        }
      }
    }
  }

  /**
   * Render the component view.
   */
  render() {
    let { loanAccount, paymentMethod } = this.props;
    let collateral = loanAccount.loanCollateral;
    let date = dayjs(this.props.date.date) //Day.js includes other languages that moment doesn't have

    // We suppose that the payment method is card default, else override the info.
    let brand = null;
    let lastFour = null;

    if (paymentMethod.paymentType === 2) { // Account bank
      brand = paymentMethod.bankName;
      lastFour = paymentMethod.lastFour;
    } else if (paymentMethod.paymentType === 3) { // Account bank
      brand = paymentMethod.bankName;
      lastFour = paymentMethod.lastFour;
    } else {
      brand = paymentMethod.cardBrand;
      lastFour = paymentMethod.lastFour;
    }



    return (
      <div className="receipt schedule-receipt">
        <div className="receipt__left-content">

          {/**
            ** Left content
            **/}
          <div className="receipt__data-section">
            <span className="money money--block money--green congratulations-modal__value">
              {toUSD(this.props.amount + this.props.convenienceFee)}
            </span>
          </div>
            
          { (this.props.convenienceFee > 0) ? this.renderConvenienceFee() : null }

          <div className="receipt__data-section">
            <p className="receipt__value">
              {(collateral.collatYear !== '' && collateral.collatMake !== '') 
                ? `${collateral.collatYear} ${collateral.collatMake}`
                : ''
              }
            </p>
            <span className="receipt__label">
              {(collateral.collatModel !== '') 
                ? loanAccount.loanCollateral.collatModel
                : ''
              }
            </span>
          </div>

          <div className="receipt__data-section">
            <p className="receipt__value">{loanAccount.loanNo}</p>
            <span className="receipt__label">{this.props.whiteLabel.labels['Loan Number']}</span>
          </div>

          <div className="receipt__data-section">
            <p className="receipt__value" title={brand}>{brand}</p>
            <span className="receipt__label">**{lastFour}</span>
          </div>
          {this.props.recurringId?<span><span className="receipt__value">{this.props.whiteLabel.labels['Group:']} {this.props.recurringId}</span></span>:''}
          
          { (this.props.isFinal == 1 && this.props.isFinalSchedule == 1) ? this.renderFinalTileMsg() : null }
        </div>

        {/**
          ** Right content
          **/}
          
        <div className="receipt__right-content">
          <div className="text-center sch_receipt__payment-status">
            <span className="nowrap-text sch_receipt__payment-status" style={{"color":this.getStampColor(), "borderColor":this.getStampColor()}}>
              {this.props.whiteLabel.labels[this.getStamp()]}
            </span>
          </div>
          <p className="text-center">
           {/**
              ** 1 is only once payment and 2 is recurring payment.
              **/}
            <span className="receipt__value nowrap-text">{this.props.whiteLabel.labels[this.getFrequency().freq]}</span>
            <br />
            <span className="receipt__value text">{this.props.whiteLabel.labels[this.getFrequency().recurring]}</span>
          </p>
          <p className="receipt__date">
            <br />
            {date.format('MMM').toUpperCase()}
            <br />
            <span className="nowrap-text">{date.date()}, {date.year()}</span>
          </p>
          {this.renderOptions()}
        </div>
      </div>
    );
  }
}

export default ScheduleReceipt;
