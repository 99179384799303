import React, { Component } from 'react';
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { toUSD } from '../../helpers/formatters';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

// Import components
import DropDownMenu from '../application/drop-down-menu';
import DropDownMenuItem from '../application/drop-down-menu/item';

class Receipt extends Component {
  state = {
    isReceiptOptionsDisplayed: false,
    visible: false
  }

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    dayjs.extend(localeData);
    var language = localStorage.getItem('language')
    import(`dayjs/locale/${language}`)
    .then(() => {
        dayjs.locale(language);
        this.forceUpdate();
    });
  }

  handleClick() {
    this.setState({ visible: ! this.state.visible });
  }

  /**
   * Display or hidden the receipt options dropdown.
   */
  displayReceiptOptions = () => {
    let status = !this.state.isReceiptOptionsDisplayed;

    this.setState({
      isReceiptOptionsDisplayed: status
    });
  }

  handleSend = (id) => {
    this.displayReceiptOptions();
    this.props.onSend(id);
  }

  renderOptions = () => {
    if (!this.props.onSend) {
      return null
    }

    return (
      (<div className="receipt__options-wrapper">
        <button className="btn-transparent" data-id="" onClick={this.displayReceiptOptions}>
          <i className="fas fa-ellipsis-v size-16 color-gray"></i>
        </button>

        <DropDownMenu isDisplayed={this.state.isReceiptOptionsDisplayed} side="right">
          <DropDownMenuItem value={this.props.orderId} onClick={this.handleSend}>
            <span className="options-menu-item">{ this.props.whiteLabel.labels['Email/SMS']}</span>
          </DropDownMenuItem>
        </DropDownMenu>
      </div>)
    )
  }

  /**
   * Render the component view.
   */
  render() {
    let date = dayjs(this.props.date.date) //Day.js includes other languages that moment doesn't have
    let amount = (this.props.showReceiptDetails || this.props.lms == 2) ? (
      <div className="receipt__data-section">
        
          <span className="money--green" style={{"font-size":"10px", "cursor":"pointer"}} onClick={this.handleClick}><span className="fa fa-info-circle"></span> More Details</span>
          <span style={{"cursor":"pointer"}} onClick={this.handleClick} className="money money--block money--green congratulations-modal__value">
            {toUSD(this.props.amount)}
          </span>
          <span></span>
          <CSSTransitionGroup transitionName="payment" className="payment-detail">
                { this.state.visible ? (
                <div className='panel panel__height-auto'>
                    { this.props.whiteLabel.labels['PRINCIPAL'] != '' ? (
                    <div>
                        <p className="receipt__value">{toUSD(this.props.principal)}</p>
                        <span className="receipt__label receipt__label-non-uppercase">{this.props.whiteLabel.labels['PRINCIPAL']}</span>
                    </div>) : null}
                    { this.props.whiteLabel.labels['INTEREST'] != '' ? (
                    <div>
                        <p className="receipt__value">{toUSD(this.props.interest)}</p>
                        <span className="receipt__label receipt__label-non-uppercase">{this.props.whiteLabel.labels['INTEREST']}</span>
                    </div>) : null}
                    { this.props.whiteLabel.labels['LATE FEE'] != '' ? (
                    <div>
                        <p className="receipt__value">{toUSD(this.props.lateFee)}</p>
                        <span className="receipt__label receipt__label-non-uppercase">{this.props.whiteLabel.labels['LATE FEE']}</span>
                    </div>) : null}
                    { this.props.whiteLabel.labels['NSF FEE'] != '' ? (
                    <div>
                        <p className="receipt__value">{toUSD(this.props.nsfFee)}</p>
                        <span className="receipt__label receipt__label-non-uppercase">{this.props.whiteLabel.labels['NSF FEE']}</span>
                    </div>) : null}
                    { this.props.whiteLabel.labels['PRINCIPAL BALANCE'] != '' ? (
                    <div>
                        <p className="receipt__value">{toUSD(this.props.principalBal)}</p>
                        <span className="receipt__label receipt__label-non-uppercase">{this.props.whiteLabel.labels['PRINCIPAL BALANCE']}</span>
                    </div>) : null}
                </div> ) : null }
              </CSSTransitionGroup>
        </div>
      ) : (
        <div className="receipt__data-section">
          <span className="money money--block money--green congratulations-modal__value">
            {toUSD(this.props.amount)}
          </span>
          <span></span>
        </div>
      );
    var receiptMessage = 'PAID'
    if (this.props.paymentStatus) {
      receiptMessage = this.props.paymentStatus.description;
    } else if (this.props.whiteLabel.labels['PAID']) {
      receiptMessage = this.props.whiteLabel.labels['PAID'];
    }
    return (
      <div className="receipt">
        {/**
          ** Left content
          **/}
        <div className="receipt__left-content">
          { amount }
          { this.props.leftContent }
        </div>

        {/**
          ** Right content
          ** This section is called in receipt page and payment confirmation modal
          **/}
        <div className="receipt__right-content">
          {(this.props.platform) ? <span className="receipt__platform">{this.props.whiteLabel.labels[this.props.platform]}</span> : null}
          <div className="text-center receipt__payment-status">
            <span className="nowrap-text receipt__payment-status" style={{"color":this.props.paymentStatus?this.props.paymentStatus.color:'green', "borderColor":this.props.paymentStatus?this.props.paymentStatus.color:'green'}}>
            {receiptMessage}
            </span>
          </div>

          <p className={this.props.dateClassName}>
            {date.format('MMM').toUpperCase()}
            <br />
            <span className="nowrap-text">
              {date.date()}, {date.year()}
            </span>
            {this.props.refundedAmt && (<React.Fragment>
              {this.props.refundedAmt != 0 && (<div className="receipt__data-section--error receipt__data-section  margin-top-20">
                <p className="receipt__value opacity-1">{this.props.refundedAmt}</p>
                <span className="">{this.props.whiteLabel.labels['REFUNDED AMT']} </span>
              </div>)}
            </React.Fragment>)}
            
          </p>
          

          {this.renderOptions()}
        </div>
      </div>
    );
  }
}

Receipt.defaultProps = {
  dateClassName: 'receipt__date'
};

export default Receipt;