import { HOST, ENDPOINTS, DEVICE } from '../constants/api';
import axios from 'axios';
import { isEmail } from 'validator';

const { login, states, checkSingUp, terms, socialSignUp, verifyUser, verifyOTP } = ENDPOINTS;

const BASE_PARAMS = {
  deviceId: DEVICE.ID,
  deviceOs: DEVICE.OS,
  deviceToken: DEVICE.TOKEN,
  deviceType: DEVICE.DEVICE_TYPE
};

export function apiSignInUser (data) {
  data = {
    ...data,
    ...BASE_PARAMS,
    lang: localStorage.getItem('language')
  }
  
  return axios({
    url: `${HOST}${login}`,
    method: 'post',
    data
  });
}

export function apiImpersonateSignIn(data) {
  let endpoint = ENDPOINTS.capToBorrowerLogin;
  localStorage.setItem('language', 'en'); // Setting languge to English for Impersonate Login
  return window.axios.post(
    process.env.REACT_APP_ROOT_URL + '/app' + endpoint, 
    data, 
    {
      headers: {
        accessToken: data.accessToken
      }
    }
  );
}

export function apiCheckSignUpUser(data) {
  data = {
    dob: '',
    ...data,
    ...BASE_PARAMS,
    lang: localStorage.getItem('language')
  }
  
  return axios({
    url: `${HOST}${checkSingUp}`,
    method: 'post',
    data
  });
}

export function apiSocialSignUp (data) {
  data = {
    ...data,
    ...BASE_PARAMS,
    lang: localStorage.getItem('language')
  }
  return axios({
    url: `${HOST}${socialSignUp}`,
    method: 'post',
    data
  });
}

export function apiVerifyUser (data) {
  data = {
    dob: '',
    ...data,
    ...BASE_PARAMS,
    lang: localStorage.getItem('language')
  }
  return axios({
    url: `${HOST}${verifyUser}`,
    method: 'post',
    data
  });
}

export function apiVerifyPhone (data) {
  data = {
    ...data,
    lang: localStorage.getItem('language')
  }
  return axios({
    url: `${HOST}${verifyOTP}`,
    method: 'post',
    data
  });
}

export function fetchStates() {
  return axios({
    url: `${HOST}${states}`,
    method: 'get'
  });
}

export function apiTermsNConditions(stateId) {
  return axios({
    url: `${HOST}${terms}?stateId=${stateId +'&lang='+localStorage.getItem('language')}`,
    method: 'get'
  });
}

export function changePassword(data) {
  data = {
    ...data,
    lang: localStorage.getItem('language')
  }
  let endpoint = ENDPOINTS.changePassword;

  return window.axios.post(endpoint, data);
}

export function changeLanguage(data) {
  let endpoint = ENDPOINTS.changeLanguage;

  return window.axios.post(endpoint, data);
}

export function resendOtpCode(email, phone) {
  let endpoint = ENDPOINTS.resendOTP;

  return window.axios.post(endpoint, {
    lang: localStorage.getItem('language'),
    phoneno: phone,
    email
  });
}

export function recoverPassword(contact) {
  let endpoint = ENDPOINTS.forgotPassword;
  let data = {
    ...data,
    lang: localStorage.getItem('language')
  }
  if (isEmail(contact)) {
    data.email = contact;
  } else {
    data.phoneno = contact;
  }

  return window.axios.post(endpoint, data);
}