import React, { Component } from 'react';
import { Application } from '../../core/app';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import moment from 'moment';

// Import actions
import { fetchMessages, sendMessage } from '../../actions/chat';
import { getClientDetails } from '../../actions/client';

// Import components
import PayixButton from '../../containers/payix-button';

class ChatOld extends Component {
  constructor(props) {
    super(props)

  }

  currentDate = null;
  scrollOnUp = false;
  loadingMoreMessages = false;

  state = {
      chatMaxCharacters: null,
      numChar: 0
  }

  /**
   * Get the initial chat messages and set the timers for get messages.
   */
  componentDidMount() {
    this.chatBox.addEventListener('scroll', this.handleScroll);
    this.props.fetchMessages();
    this.props.getClientDetails().then( (data) =>{
      this.setState({
        chatMaxCharacters: data.value.data.result.data.chatMaxCharacter
      });
    });
    // Set an interval for request messages each 4 seconds.
    // If user has chat scroll up, not make requests for get messages.
    this.timerId = setInterval(() => {
      if ((this.chatBox.scrollTop + this.chatBox.clientHeight) === this.chatBox.scrollHeight) {
        this.props.fetchMessages();
      }
    }, 4000);
  }

  /**
   * Automatically scrolls the chat every time new messages are obtained, 
   * but only if the user has the chat scrolled to the end.
   */
  componentDidUpdate() {
      if (!this.scrollOnUp) {
          this.chatBox.scrollTop = this.chatBox.scrollHeight;
      }
  }

  /**
   * Stop the timer before component is unmounted from the DOM.
   */
  componentWillUnmount() {
    clearInterval(this.timerId);
    clearInterval(this.timerScrollId);
    this.chatBox.removeEventListener('scroll', this.handleScroll);
    
  }

  /**
   * Check if the chat is completely scrolled until the end.
   */
  isScrolledToDown() {
    return (this.chatBox.scrollTop + this.chatBox.clientHeight) === this.chatBox.scrollHeight;
  }

  /**
   * Handle event for load more messages.
   */
  loadMoreMessages = () => {
    if (this.loadingMoreMessages) {
      return ;
    }

    this.loadingMoreMessages = true;

    this.props.fetchMessages(this.props.currentPage + 1, true)
      .then(() => {
        this.loadingMoreMessages = false;
      })
      .catch(() => {
        this.loadingMoreMessages = false;
        Application.showInternalError();
      });
  }

  /**
   * Verify where the user has scrolled the chat, up or down.
   * to indicate to the system if it should move automatically in the arrival of new messages.
   */
  handleScroll = () => {
    if (this.isScrolledToDown()) {
      this.scrollOnUp = false;
    } else {
      this.scrollOnUp = true;
    }
  }

  /**
   * Handle event for send a new message.
   */
  handleSendMessageButton = () => {
    this.sendMessage(this.messageField.value);
    this.setState({
      numChar: 0
    })
  }

  /**
   * Handle the event to send a new message with the enter key.
   * 
   * @param {*} event
   */
  handleKeyDownEvent = (event) => {
    let size = event.target.value.length
    this.setState({
      numChar: size
    })
    if (event.keyCode === 13 || event.key === 'Enter') {
      event.preventDefault();
      this.setState({
        numChar: 0
      })
      this.sendMessage(this.messageField.value);

      return false;
    }
  }

  /**
   * Send a new message.
   * 
   * @param {string} message
   */
  sendMessage(message) {
    if (message.length > 0) {
      this.props.sendMessage(message)
        .then(() => {
          this.props.fetchMessages();
        }).catch(() => {
        });

      this.messageField.value = '';
    }
  }

  /**
   * Check if the message is outgoing or not.
   * 
   * @param {*} message 
   */
  isAnOutgoingMessage(message) {
    return message.type === 2;
  }

  /**
   * Format the date of the message to the user's local time zone.
   * 
   * @param {*} date 
   */
  getDate(date) {
    let utc = moment.utc(date).local().calendar().split(" ");
    if (utc[0] === 'Today') {
      return this.props.whiteLabel.labels['Today'];
    }

    return moment.utc(date).local().format('MM/DD/YYYY');
  }

  /**
   * Format the message time to the user's local time zone.
   * 
   * @param {*} date 
   */
  getTime(date) {
    return moment.utc(date).local().format('hh:mm A');
  }

  /**
   * Render the load more link for get old messages.
   */
  renderLoadMore() {
    if (this.props.totalPages > this.props.currentPage) {
      return (
        <div className="chat__load-more" onClick={ this.loadMoreMessages }>
          <p>{this.props.whiteLabel.labels['Load more messages']}</p>
        </div>
      )
    }

    return null;
  }

  /**
   * Render the entire list of messages obtained.
   */
  renderMessages() {
    let messages = Object.assign([], this.props.messages);
    messages.reverse();

    return messages.map((message) =>
      this.renderMessage(message)
    )
  }

  /**
   * Render a message in the chat.
   */
  renderMessage = (message) => {
    // Get the appropriate CSS modifiers to show the message if it is incoming or outgoing.
    let messageModifier = (this.isAnOutgoingMessage(message)) 
      ? 'chat__message--outgoing' : 'chat__message--incoming';

    let messageContentModifier = (this.isAnOutgoingMessage(message)) 
      ? 'chat__message-content--outgoing' : 'chat__message-content--incoming';

    let messageTimeModifier = (this.isAnOutgoingMessage(message)) 
      ? 'chat__message-time--outgoing' : 'chat__message-time--incoming';
    
    // Extract the date and time.
    let date = this.getDate(message.datetime.date);
    let time = this.getTime(message.datetime.date);

    // Build the markup for show the date.
    let dateMarkup = null;

    if (this.currentDate === null || this.currentDate !== date) {
      this.currentDate = date;

      dateMarkup = (
        <div className="chat__date-wrapper">
          <span className="chat__message-date">
            {date}
          </span>
        </div>
      )
    }

    return (
      <div className="chat__new-message" key={ message.id }>
        { dateMarkup }
        <div className={ 'chat__message ' + messageModifier }>
          {/* Message */}
          <p className={ 'chat__message-content ' + messageContentModifier }>
            { message.message }
          </p>

          {/* Message time */}
          <span className={`chat__message-time ${messageTimeModifier}`}>
            { time }
          </span>
        </div>
      </div>
    );
  }

  /**
   * Render the component view.
   */
  render() {
    let messages = Object.assign([], this.props.messages);
    messages.reverse();

    // Get the css classes for styling the loader.
    let msgLoaderCss = 'chat__msg-loader';
    msgLoaderCss += (!this.props.loadingMessages) ? ' chat__msg-loader--hidden' : '';

    this.currentDate = null;

    return (
      <div className="card chat">
        <div className="overflow-y-auto" ref={(div) => this.chatBox = div}>
            {this.renderLoadMore()}
            <div className="chat__messages">
                {/**
                  ** Render the messages.
                  **/}
                {this.renderMessages()}
            </div>
        </div>
        {/**
          ** Write a message field.
          **/}
        <div className="chat__write-message-wrapper">
          <div className="chat__write-message">
            <textarea className="chat__message-field" rows="3" ref={(input) => this.messageField = input} 
              onKeyUp={ this.handleKeyDownEvent } placeholder={this.props.whiteLabel.labels['Write message']} maxLength={this.state.chatMaxCharacters}>
            </textarea>

            <span className={ msgLoaderCss }>
              <i className="fas fa-spinner fa-spin"></i>
            </span>

            <div>
              <PayixButton className="chat__send-button" onClick={this.handleSendMessageButton}>
                  {this.props.whiteLabel.labels['Send']}
              </PayixButton>
              <div>
                  <br/>
                  <span className="account-details__data">
                      {this.state.numChar}/{this.state.chatMaxCharacters}
                  </span>
                </div>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Map the state and generate props to pass it to component.
 *
 * @param {*} state
 */
const mapStateToProps = (state, ownProps) => {
  return {
    loadingMessages: state.chat.loading,
    currentPage: state.chat.currentPage,
    totalPages: state.chat.totalPage,
    messages: state.chat.messages,
    whiteLabel : state.whiteLabel,
    chatMaxCharacters : state.client.data.chatMaxCharacter
  }
}

/**
 * Create handlers to dispatch actions to store y pass them at component as props.
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchMessages, sendMessage, getClientDetails
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatOld));