// Payment methods.
export const PAYMENT_TYPE_CARD = 1;
export const PAYMENT_TYPE_ACH = 2;
export const PAYMENT_TYPE_PAD = 3;

// Frequencies.
export const ONLYONCE = 'onlyonce'; 
export const WEEKLY = 'WE';
export const MONTHLY = 'MO';
export const TWICE_A_MONTH = 'SM';
export const TWICE_A_MONTH14 = 'S4';
export const EVERY_TWO_WEEKS = 'BW';

// Facebook status
export const FB_NOT_AUTHORIZED = 'not_authorized';