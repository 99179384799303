import * as frequency from '../constants/app';

export function getMonthNumbers() {
  return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
}

export function getYearsFromCurrent() {
  let years = [];
  let currentYear = new Date().getFullYear();

  for (let i = currentYear; i <= 2043; i++) {
    years.push(i);
  }

  return years;
}

export function paymentFrequency(abb) {
  switch (abb) {
    case frequency.WEEKLY :
      return 'Weekly';
    case frequency.MONTHLY :
      return 'Monthly'
    case frequency.TWICE_A_MONTH :
    case frequency.TWICE_A_MONTH14 :
      return 'Twice a month';
    case frequency.EVERY_TWO_WEEKS :
      return 'Every two weeks';
    default :
      return 'Undefined frequency';
  }
}

export function getMonth(date) {
  return date.toLocaleString('en-us', { month: "short" });
}

/**
 * Check that value has only numbers.
 */
export function onlyNumbers(value) {
  return value.replace(/\D/, '');
}

/**
 * Return the ssn with only the last four numbers and rest with x characters.
 * 
 * @param {*} lastFour 
 */
export function obfuscateSsn(lastFour) {
  return 'XXX-XX-' + lastFour;
}

/**
 * Find a payment method by id.
 * 
 * @param {} id 
 * @param {*} paymentMethods 
 */
export function findPaymentMethod(id, paymentMethods) {
  return paymentMethods.find(paymentMethod => paymentMethod.id === id);
}

/**
 * Get a specific payment method by its id and type.
 * 
 * @param Number id - The payment method id.
 * @param Number type - The payment type.
 * @param {*} paymentMethods - The payment methods collection for search.
 */
export function findPaymentMethodByType(id, type, paymentMethods) {
  return paymentMethods.find(paymentMethod => (paymentMethod.id === id && paymentMethod.paymentType === type))
}

/**
 * Find a object by its id property.
 * 
 * @param {} id 
 * @param {*} collection 
 */
export function findById(id, collection) {
  return collection.find(item => item.id === id);
}

/**
 * Check if two dates are equals or not.
 * 
 * @param {*} date1 
 * @param {*} date2 
 */
export function areEqualDates(date1, date2) {
  let day1 = date1.getDate(),
    month1 = date1.getMonth(),
    year1 = date1.getFullYear();

  let day2 = date2.getDate(),
    month2 = date2.getMonth(),
    year2 = date2.getFullYear();

  if ((day1 === day2) & (month1 === month2) && (year1 === year2)) {
    return true;
  }

  return false;
}

/**
 * Check if the status for the http response is OK.
 * 
 * @param {*} statusCode 
 */
export function isOkHttpResponse(statusCode) {
  return (statusCode >= 200 && statusCode < 300);
}

/**
 * Check if the visibility of the website is hidden or visible
 * 
 * @param {*} statusCode 
 */
 export function checkVisibilityWebsite() {
  return !document.hidden;
}